import { COMPOSE_AB_SCRIPT } from '@/services/Configuration'
import { log } from '@/services/Log'
import React from 'react'

const getRandomInt = (max: number) => Math.floor(Math.random() * max)
const ComposeABScript: React.FC = () => (
  <script
    type="application/javascript"
    src={`${COMPOSE_AB_SCRIPT}?rf=${getRandomInt(1000000)}`}
    onLoad={() => {
      log('ComposeABScript loaded')
      window.dispatchEvent(new Event('composeABScriptLoaded'))
    }}
  />
)

export default ComposeABScript
